























































































































































































@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.row-item {
  padding: 0 1rem;
}
.wh-form-range {
  > div {
    height: 38.54px;
  }
}
